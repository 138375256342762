html {
    width: 100%;
    font-size: 62.5%;
    scroll-behavior: smooth;
}

input,
textarea,
select,
button {
    font: inherit;
}

button {
    appearance: none;
    border: none;
    background-color: transparent;
    color: inherit;
}
